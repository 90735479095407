import "./src/styles/global.css"

export const onClientEntry = () => {
}

export const onRouteUpdate = () => {
    
    // remove the stat counter script
    const script = document.querySelector('#statcounter')
    script.remove()
    console.log('removedScript')

    // add the statcounter script again to emulate page load.
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.id = 'statcounter';
    s.src = "https://www.statcounter.com/counter/counter.js";
    document.body.appendChild(s);
}